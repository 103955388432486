<bit-dialog dialogSize="large" [title]="dialogHeader">
  <ng-container bitDialogContent>
    <app-payment-v2
      [showAccountCredit]="false"
      [showBankAccount]="!!organizationId"
      [initialPaymentMethod]="initialPaymentMethod"
    ></app-payment-v2>
    <app-tax-info (onCountryChanged)="onCountryChanged()"></app-tax-info>
  </ng-container>
  <ng-container bitDialogFooter>
    <button type="submit" bitButton bitFormButton buttonType="primary" [bitAction]="submit">
      {{ "submit" | i18n }}
    </button>
    <button
      type="button"
      bitButton
      bitFormButton
      buttonType="secondary"
      [bitDialogClose]="ResultType.Closed"
    >
      {{ "cancel" | i18n }}
    </button>
  </ng-container>
</bit-dialog>
