<bit-dialog>
  <span bitDialogTitle>{{ "areYouTryingtoLogin" | i18n }}</span>
  <ng-container bitDialogContent>
    <h4 class="tw-mb-3">{{ "logInAttemptBy" | i18n: email }}</h4>
    <div>
      <b>{{ "fingerprintPhraseHeader" | i18n }}</b>
      <p class="tw-text-code">{{ fingerprintPhrase }}</p>
    </div>
    <div>
      <b>{{ "deviceType" | i18n }}</b>
      <p>{{ authRequestResponse?.requestDeviceType }}</p>
    </div>
    <div>
      <b>{{ "ipAddress" | i18n }}</b>
      <p>{{ authRequestResponse?.requestIpAddress }}</p>
    </div>
    <div>
      <b>{{ "time" | i18n }}</b>
      <p>{{ requestTimeText }}</p>
    </div>
  </ng-container>
  <ng-container bitDialogFooter>
    <button
      bitButton
      type="button"
      buttonType="primary"
      [bitAction]="approveLogin"
      [bitDialogClose]="true"
    >
      {{ "confirmLogIn" | i18n }}
    </button>
    <button
      bitButton
      type="button"
      buttonType="secondary"
      [bitAction]="denyLogin"
      [bitDialogClose]="true"
    >
      {{ "denyLogIn" | i18n }}
    </button>
  </ng-container>
</bit-dialog>
