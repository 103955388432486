<app-header></app-header>

<bit-container>
  <tools-credential-generator />
  <bit-item>
    <button
      type="button"
      bitLink
      bit-item-content
      aria-haspopup="true"
      (click)="openHistoryDialog()"
    >
      {{ "generatorHistory" | i18n }}
      <i slot="end" class="bwi bwi-angle-right" aria-hidden="true"></i>
    </button>
  </bit-item>
</bit-container>
